// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "z_tC d_gS d_cw d_dv";
export var quoteParagraphCenter = "z_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "z_gV d_gV d_cw d_dx";
export var quoteRowLeft = "z_tD d_bG";
export var quoteRowCenter = "z_tF d_bD";
export var quoteRowRight = "z_tG d_bH";
export var quoteWrapper = "z_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "z_gR d_gR";
export var quoteExceptionSmall = "z_tH G_tH";
export var quoteExceptionNormal = "z_tJ G_tJ";
export var quoteExceptionLarge = "z_tK G_tK";
export var quoteAuthorExceptionSmall = "z_tL G_tL";
export var quoteAuthorExceptionNormal = "z_tM G_tM";
export var quoteAuthorExceptionLarge = "z_tN G_tN";