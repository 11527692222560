// extracted by mini-css-extract-plugin
export var alignLeft = "w_q9 d_bG";
export var alignCenter = "w_bP d_bD";
export var alignRight = "w_rb d_bH";
export var textAlignLeft = "w_s0";
export var textAlignCenter = "w_s1";
export var textAlignRight = "w_s2";
export var hoursInnerWrapperAlt = "w_s3 d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "w_s4 d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "w_s5 d_dw";
export var titleMargin = "w_s6 d_cw";
export var hoursInnerInnerWrapper = "w_s7 d_cz";