// extracted by mini-css-extract-plugin
export var alignLeft = "C_q9 d_fp d_bG d_dv";
export var alignCenter = "C_bP d_fq d_bD d_dw";
export var alignRight = "C_rb d_fr d_bH d_dx";
export var testimonialsContainer = "C_tY d_dW";
export var testimonialsContainerFull = "C_tZ d_dT";
export var testimonialsMainHeader = "C_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "C_jy d_jy";
export var testimonialsComponentSmall = "C_jx d_jx";
export var testimonialsComponentsWrapper = "C_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "C_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "C_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "C_t0 d_d7";
export var colEqualHeight = "C_t1 d_bz";
export var testimonialsColumnWrapper = "C_jz d_jz d_b3";
export var testimonialsImageWrapper = "C_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "C_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "C_jr d_jr d_Z d_bz";
export var design2TextWrapper = "C_tB d_bC d_bP d_bJ";
export var design3 = "C_t2 d_cD d_H";
export var imageDesign2 = "C_t3";
export var SmallSmall = "C_q6 G_q6 G_t8 G_vw";
export var SmallNormal = "C_q7 G_q7 G_t8 G_vx";
export var SmallLarge = "C_q8 G_q8 G_t8 G_vt";
export var exceptionWeight = "C_sG G_vB";