// extracted by mini-css-extract-plugin
export var customText = "r_rr d_dv d_cs d_cg";
export var videoIframeStyle = "r_qp d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "r_rs d_cs d_cg d_Z";
export var customRow = "r_qK d_bD d_bf";
export var quoteWrapper = "r_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "r_qt d_H";
export var masonryImageWrapper = "r_qB";
export var title = "r_qX";
export var Title3Small = "r_rt G_rt G_t8 G_vh";
export var Title3Normal = "r_rv G_rv G_t8 G_vj";
export var Title3Large = "r_rw G_rw G_t8 G_vk";