// extracted by mini-css-extract-plugin
export var carouselContainer = "L_wr d_w d_H d_bf d_Z";
export var carouselContainerCards = "L_ws L_wr d_w d_H d_bf d_Z";
export var carouselContainerSides = "L_wt d_w d_H d_Z";
export var prevCarouselItem = "L_wv d_w d_H d_0 d_k";
export var prevCarouselItemL = "L_ww L_wv d_w d_H d_0 d_k";
export var moveInFromLeft = "L_wx";
export var prevCarouselItemR = "L_wy L_wv d_w d_H d_0 d_k";
export var moveInFromRight = "L_wz";
export var selectedCarouselItem = "L_wB d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "L_wC L_wB d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "L_wD L_wB d_w d_H d_Z d_bf";
export var nextCarouselItem = "L_wF d_w d_H d_0 d_k";
export var nextCarouselItemL = "L_wG L_wF d_w d_H d_0 d_k";
export var nextCarouselItemR = "L_wH L_wF d_w d_H d_0 d_k";
export var arrowContainer = "L_wJ d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "L_wK L_wJ d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "L_wL L_wK L_wJ d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "L_wM L_wJ d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "L_wN";
export var nextArrowContainerHidden = "L_wP L_wM L_wJ d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "L_kG d_0";
export var prevArrow = "L_wQ L_kG d_0";
export var nextArrow = "L_wR L_kG d_0";
export var carouselIndicatorContainer = "L_wS d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "L_wT d_w d_bz d_bF";
export var carouselText = "L_wV d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "L_wW L_wV d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "L_wX d_b7";
export var carouselIndicator = "L_wY L_wX d_b7";
export var carouselIndicatorSelected = "L_wZ L_wX d_b7";
export var arrowsContainerTopRight = "L_w0 d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "L_w1 d_0 d_bl d_bC";
export var arrowsContainerSides = "L_w2 d_0 d_bl d_bC";
export var smallArrowsBase = "L_w3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "L_w4 L_w3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "L_w5 L_w4 L_w3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "L_w6 L_w3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "L_w7 L_w6 L_w3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "L_qJ";
export var iconSmall = "L_w8";
export var multipleWrapper = "L_w9 d_bC d_bF d_bf";
export var multipleImage = "L_xb d_bC";
export var sidesPrevContainer = "L_xc L_w4 L_w3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "L_xd L_w4 L_w3 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";