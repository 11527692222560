// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "v_sH d_gv d_cs";
export var heroHeaderCenter = "v_gw d_gw d_cs d_dw";
export var heroHeaderRight = "v_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "v_sJ d_gr d_cw";
export var heroParagraphCenter = "v_gs d_gs d_cw d_dw";
export var heroParagraphRight = "v_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "v_sK d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "v_sL d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "v_sM d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "v_sN d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "v_sP d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "v_sQ G_sQ";
export var heroExceptionNormal = "v_sR G_sR";
export var heroExceptionLarge = "v_sS G_sS";
export var Title1Small = "v_sT G_sT G_t8 G_t9";
export var Title1Normal = "v_sV G_sV G_t8 G_vb";
export var Title1Large = "v_sW G_sW G_t8 G_vc";
export var BodySmall = "v_sX G_sX G_t8 G_vs";
export var BodyNormal = "v_sY G_sY G_t8 G_vt";
export var BodyLarge = "v_sZ G_sZ G_t8 G_vv";