// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "B_tP d_bD";
export var storyRowWrapper = "B_hx d_hx d_bK";
export var storyLeftWrapper = "B_tQ d_bz d_bP";
export var storyWrapperFull = "B_tR d_cD";
export var storyWrapperFullLeft = "B_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "B_mv d_hy";
export var storyLeftWrapperCenter = "B_tS d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "B_tT d_hF";
export var storyHeader = "B_tV d_hD d_w d_cs";
export var storyHeaderCenter = "B_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "B_hB d_hB d_by d_dw";
export var storyBtnWrapper = "B_tW d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "B_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "B_q5 d_fg d_Z";
export var imageWrapperFull = "B_tX d_w d_H d_bf d_Z";
export var SubtitleSmall = "B_qM G_qM G_t8 G_vl";
export var SubtitleNormal = "B_qN G_qN G_t8 G_vm";
export var SubtitleLarge = "B_qP G_qP G_t8 G_vn";
export var textLeft = "B_dv";
export var textCenter = "B_dw";
export var textRight = "B_dx";