// extracted by mini-css-extract-plugin
export var articleWrapper = "n_p7";
export var articleText = "n_p8 d_dv d_cs";
export var header = "n_p9 d_Z d_w";
export var headerImageWrapper = "n_qb d_bd d_0 d_8 d_7 d_4 d_9 d_bk d_bR";
export var headerGradient = "n_qc d_bd d_0 d_8 d_7 d_4 d_9";
export var headerGradientOverlay = "n_qd d_bd d_0 d_8 d_7 d_4 d_9";
export var headerContentWrapper = "n_qf d_bz d_bP d_bD d_bJ d_bl d_Z";
export var headerContentWrapperAlt = "n_qg d_bz d_bP d_bD d_bJ d_bl d_Z";
export var contentWrapper = "n_mv d_w";
export var dateTag = "n_qh d_cf d_cr d_dq d_w";
export var icon1 = "n_qj d_cP";
export var icon2 = "n_qk d_cP d_cJ";
export var tagBtn = "n_ql d_b1 d_cD d_dp d_dB";
export var headerText = "n_qm d_w d_cf d_cs d_bW";
export var center = "n_qn d_dw";
export var videoIframeStyle = "n_qp d_d5 d_w d_H d_by d_b1 d_R";
export var articleImageWrapper = "n_qq d_cs d_Z";
export var articleImageWrapperIcon = "n_qr d_cs d_Z";
export var articleRow = "n_qs d_bD";
export var quoteWrapper = "n_gQ d_bC d_bP d_cs";
export var quoteBar = "n_qt d_H";
export var quoteText = "n_qv";
export var authorBox = "n_qw d_w";
export var authorRow = "n_qx d_bD d_bW d_cy";
export var separator = "n_qy d_w";
export var line = "n_fk d_w d_fk d_cY d_cy";
export var authorImage = "n_ln d_b7 d_Z d_w d_ct";
export var authorText = "n_qz d_ch d_ct";
export var masonryImageWrapper = "n_qB";
export var bottomSeparator = "n_qC d_w d_cy";
export var linksWrapper = "n_qD d_ch d_dv";
export var comments = "n_qF d_bD d_cy";
export var sharing = "n_qG d_bz d_bD d_bJ";
export var shareText = "n_qH d_w d_dw";
export var icon = "n_qJ";
export var customRow = "n_qK d_bD d_Z";
export var text = "n_qL";
export var SubtitleSmall = "n_qM";
export var SubtitleNormal = "n_qN";
export var SubtitleLarge = "n_qP";