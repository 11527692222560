// extracted by mini-css-extract-plugin
export var campaignContainer = "p_qQ d_dW";
export var campaignContainerFull = "p_qR d_dT";
export var wrapper = "p_qS d_w";
export var leftWrapper = "p_qT d_bz d_bN";
export var rightWrapper = "p_qV";
export var subtitle = "p_qW";
export var title = "p_qX";
export var text = "p_qL d_cs";
export var inlineWrapper = "p_qY d_bC d_bG d_w";
export var btnsWrapper = "p_qZ p_qY d_bC d_bG d_w d_bJ";
export var priceWrapper = "p_q0 p_qY d_bC d_bG d_w d_ch d_bP";
export var priceHeading = "p_q1";
export var disclaimerWrapper = "p_q2 d_cD";
export var mediaSlot = "p_q3";
export var listWrapper = "p_q4 d_bC d_bP d_w";
export var btnWrapper = "p_d2";
export var imageWrapper = "p_q5 d_fg d_Z";
export var SmallSmall = "p_q6 G_q6 G_t8 G_vw";
export var SmallNormal = "p_q7 G_q7 G_t8 G_vx";
export var SmallLarge = "p_q8 G_q8 G_t8 G_vt";
export var textLeft = "p_dv";
export var textCenter = "p_dw";
export var textRight = "p_dx";
export var alignLeft = "p_q9";
export var alignCenter = "p_bP";
export var alignRight = "p_rb";